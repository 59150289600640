/* src/RadioPlayer.css */
.radio-player {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('./background-2.webp'); /* Replace with the correct path to your image */
  background-size: cover; /* Ensure the background covers the full area */
  background-repeat: no-repeat; /* Do not repeat the background */
  background-position: center center; /* Center the background image */
  background-attachment: scroll; /* Normal scrolling behavior */
}

.radio-title {
  font-size: 3vw; /* Use vw for responsive font size */
  color: white;
  font-weight: bold;
  font-family: 'Futura', sans-serif;
  margin: 0;
  padding: 0;
}

.radio-version {
  font-size: 1.2vw; /* Use vw for responsive font size */
  color: white;
  font-weight: normal;
  font-family: 'Arial', sans-serif;
  margin-top: 1vw;
}

.play-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 2vw;
}

.play-button img {
  width: 8vw; /* Use vw for responsive image size */
  height: auto; /* Keep the aspect ratio */
  max-width: 80px; /* Set a max width to ensure it's not too large */
}

@media (max-width: 768px) {
  .radio-title {
    font-size: 6vw; /* Increase font size for smaller screens */
  }

  .radio-version {
    font-size: 2vw; /* Increase font size for smaller screens */
  }

  .play-button img {
    width: 12vw; /* Increase button size for smaller screens */
  }
}

@media (max-width: 480px) {
  .radio-title {
    font-size: 8vw; /* Further increase font size for very small screens */
  }

  .radio-version {
    font-size: 3vw; /* Further increase font size for very small screens */
  }

  .play-button img {
    width: 16vw; /* Further increase button size for very small screens */
  }
}
.photo-credit {
  position: absolute;
  bottom: 1vw;
  right: 1vw;
  color: white;
  font-size: 0.6vw;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.7);
  z-index: 10;
  max-width: 300px;
  opacity: 0.7; /* Set the opacity to 70% */
  padding: 0.5vw; /* Add some padding around the text */
  border-radius: 5px; /* Optional: round the corners of the background */
}

@media (max-width: 768px) {
  .photo-credit {
    font-size: 2vw;
    bottom: 2vw;
    right: 2vw;
  }
}

@media (max-width: 480px) {
  .photo-credit {
    font-size: 2.5vw;
    bottom: 4vw;
    right: 4vw;
  }
}
/* ... (previous CSS code remains unchanged) ... */

.radio-description {
  position: absolute;
  top: 1vw;
  right: 1vw;
  color: white;
  font-size: 0.6vw;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.7);
  z-index: 10;
  max-width: 300px;
  opacity: 0.7; /* Set the opacity to 70% */
  padding: 0.5vw; /* Add some padding around the text */
  border-radius: 5px; /* Optional: round the corners of the background */
}

@media (max-width: 768px) {
  .radio-description {
    font-size: 2vw;
    top: 2vw;
    right: 2vw;
  }
}

@media (max-width: 480px) {
  .radio-description {
    font-size: 2.5vw;
    top: 4vw;
    right: 4vw;
  }
}
